import { createSlice, TypeGuards } from '@codeleap/common'
import { api, LocalStorageKeys, Settings } from '@/app'

const modals = {
  test: false,
  network: false,
  debug: false,
  auth: false,
  login: false,
  signup: false,
  forgotBefore: false,
  forgotAfter: false,
  verifyEmailBefore: false,
  verifyEmailAfter: false,
  aboutYou: false,
  aboutJob: false,
  success: false,
  addOrganisation: false,
  authWall: false,
  onboarding: false,
  profileRedirect: false,
  organisationInfo: false,
  congratulationsStories: false,
  storyDetail: false,
  createStory: false,
  noReviewQuestions: false,
  unknownOrganisation: false,
  getOrgInvolved: false,
}

export type TAppStatus = {
  status: 'loading' | 'done' | 'idle' | 'splash' | 'blank' | 'interactive'
  ready: boolean
  modals: Record<keyof typeof modals, any>
  isDev: boolean
  canGoBlank: boolean
  authFinished: boolean
  hasResolvedInitialAuth: boolean
}

const initialState: TAppStatus = {
  status: 'idle',
  ready: false,
  modals,
  canGoBlank: true,
  authFinished: false,
  isDev: false,
  hasResolvedInitialAuth: false,
}

export type ModalName = keyof TAppStatus['modals']

export const appStatusSlice = createSlice({
  name: 'AppStatus',
  initialState,
  reducers: {
    initialAuthResolved() {
      return {
        hasResolvedInitialAuth: true,
      }
    },
    setModal(state, modal: [ModalName, boolean] | ModalName) {
      const [name, value] = Array.isArray(modal) ? modal : [modal, !state.modals[modal]]

      return {
        modals: {
          [name]: value,
        },
      }
    },
    setReady(state, to: boolean) {
      return {
        ready: to,
      }
    },
    toggleDevMode(state, setTo = null) {
      let newState = !state.isDev

      if (TypeGuards.isBoolean(setTo)) {
        newState = setTo
      }

      api.setConfig({
        baseURL: newState ? Settings.Fetch.DevelopmentURL : Settings.Fetch.ProductionURL,
      })

      localStorage.setItem(LocalStorageKeys.SESSION_IS_DEV, newState ? 'true' : 'false')

      return {
        isDev: newState,
      }
    },
    clearModals(state) {
      return {
        modals,
      }
    },
  },
  asyncReducers: {
    async authFinished(state, setState, to = true) {
      setState({ modals: initialState.modals })
    },
    async set(state, setState, status: TAppStatus['status']) {

      // HACK this fixes the animation transition blinking between the splash screen and idle

      setState({ status })

    },
    async transitionModals(state, setState, [from, to]: [ModalName, ModalName]) {
      const timeout = state?.status !== 'idle' ? 2500 : 400

      const modals = { ...state.modals }
      modals[from] = false

      setState({ modals })

      setTimeout(() => {
        modals[to] = true
        setState({ modals })
      }, timeout)
    },
  },
})
